import { AlertTexts } from '../language/Texts';
import translation from '../language/translation.json';
import { ErrorTexts } from '../models/ErrorTexts';
import { IKeyValue } from '../models/IComponent';
import { ComponentType } from '../models/componentType.enum';

export class Utils {

    static convertBackgroundColorToRgba(color: string): string {
        const hex = color.replace('#', '');
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        return `rgba(${r}, ${g}, ${b}, 0.7)`;
    }

    static arrangeUrl(url: string): string {
        if (!(url.startsWith('http://') || url.startsWith('https://'))) {
            url = 'https://' + url
        }
        return url;
    }

    static getBrowserLanguage(): string {
        return navigator.language.substring(0, 2)
    }

    static isLanguageSupported(lang: string): boolean {
        return translation.language[lang]
    }

    static getInfoPartTitleClassName = (isEditorView: boolean) => {
        if (!isEditorView) return 'info_part_title'
        return 'info_part_title editor_info_part_title'
    }

    static errorConvertor = (error: string) => {
        switch (error) {
            case ErrorTexts.COMPONENT_EXISTS:
                return AlertTexts.componentExists
            case ErrorTexts.OUT_OF_EMAIL_LIMIT:
                return AlertTexts.outOfEmailLimit
            default:
                return error
        }
    }

    static getSnowVariables() {
        let speed: [number, number] = [0.5, 1]
        let size: [number, number] = [10, 12]
        let quantity = 50
        if (window.screen.width > 768) {
            speed = [0.5, 1]
            size = [12, 15]
            quantity = 100
        }
        if (window.screen.width > 1200) {
            speed = [0.5, 1]
            size = [7, 12]
            quantity = 130
        }
        if (window.screen.width > 2000) {
            speed = [1, 1]
            size = [10, 15]
            quantity = 180
        }
        return { speed, quantity, size }
    }

    static isTypeBatteryPass(type: ComponentType): boolean {
        return type === ComponentType.BATTERY_PASS
    }

    static getBackgroundColor(type: ComponentType): string {
        if (type === ComponentType.GEMUE_INTERNAL) {
            return 'rgba(133, 133, 233, 0.7)'
        }
        return this.isTypeBatteryPass(type) ? 'rgba(90, 182, 243, 0.7)' : 'rgba(210, 224, 146, 0.7)'
    }

    static getDefaultParameters() {
        let type: ComponentType = ComponentType.DIGITAL_NAMEPLATE
        let backgroundColor: string = "#D2E092"
        let defaultProductName: string = "Beispiel Produkt"

        const paramString = window?.location?.href?.split('?')[1];
        if (paramString) {
            const queryString = new URLSearchParams(paramString);
            for (let pair of queryString.entries()) {
                if (pair[0] === "type" && (pair[1] === ComponentType.DIGITAL_NAMEPLATE || pair[1] === ComponentType.BATTERY_PASS || pair[1] === ComponentType.GEMUE_INTERNAL)) {
                    type = pair[1]
                    if (pair[1] === ComponentType.BATTERY_PASS) {
                        backgroundColor = "#5ab6f3"
                        defaultProductName = "Battery Passport"
                    }
                    if (pair[1] === ComponentType.GEMUE_INTERNAL) {
                        backgroundColor = "#858585"
                    }
                } else {
                    type = ComponentType.DIGITAL_NAMEPLATE
                }
            }
        }

        return { type, backgroundColor, defaultProductName }
    }

    static getRedirectInfos() {
        const host = window.location.host;
        const isEditorView = (host === "d.asset-pass.com" || host === "e.asset-pass.com")
        const isStagingMode = host.includes("e.asset-pass.com")
        let redirectUrl = isStagingMode ? 'https://e.asset-pass.com' : 'https://d.asset-pass.com'
        if (this.isBatteryPass()) {
            redirectUrl += "?type=battery-pass"
        }
        if (this.isGemueInternal()) {
            redirectUrl += "?type=gemue-internal"
        }
        return { isEditorView, redirectUrl }
    }

    static isBatteryPass() {
        return window.location.search.includes("type=battery-pass")
    }

    static isGemueInternal() {
        return window.location.search.includes("type=gemue-internal")
    }

    static getKeyValue(): IKeyValue[] {
        if (this.isBatteryPass()) {
            return [{ key: 'Kapazität', value: '74,24 kWh' }, { key: 'Gewicht', value: '438 Kg' }]
        }
        return [{ key: 'Material', value: 'Metall' }, { key: 'Größe', value: '10mm' }]
    }
}
import { FormTexts } from '../../../language/Texts'
import { ComponentType } from '../../../models/componentType.enum'
import { Utils } from '../../../utils/Utils'
import './FormTitle.scss'

interface FormTitleProps {
    type: ComponentType
}

function FormTitle({ type }: FormTitleProps) {
    const title = Utils.isTypeBatteryPass(type) ? FormTexts.formTitleBatteryPass : FormTexts.formTitle
    let subTitle = Utils.isTypeBatteryPass(type) ? FormTexts.formSubTitleBatteryPass : FormTexts.formSubTitle
    if (type === ComponentType.GEMUE_INTERNAL) {
        subTitle = FormTexts.formSubTitleGemueInternal
    }

    return (
        <>
            <div className="form_title">
                {title}
            </div>
            <div className="form_sub_title">
                {subTitle}
            </div>
        </>
    )
}

export default FormTitle

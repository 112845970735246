import pbLogo from '../../../assets/pb.png'
import { ComponentType } from '../../../models/componentType.enum'
import './EditorFooter.scss'

interface Props {
    type: ComponentType
}

function EditorFooter({ type }: Props) {
    const dtuUrl = "https://gemugroup.sharepoint.com/sites/T_DTU/?xsdata=MDV8MDJ8fGIxZjk4YzkzMGM4MzQyNTVkOThjMDhkZDQ5ZTVkNDE1fGExMDJkZGU0NDRhNDQzZGQ5MmNiNTNjNTQzZmMwZjBjfDB8MHw2Mzg3NDc5NzM2MTEwMTg2MzZ8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPalJtT1RsbU56azVMVGsxTkRRdE5HSXdZUzA0TURrekxXRTJaalEwTTJJME5XVmlNbDgxWWpNellUYzVaaTB5T1dWa0xUUXpZV0V0T0dKaFlpMHdNVEZpWkRabU5UTm1aVGxBZFc1eExtZGliQzV6Y0dGalpYTXZiV1Z6YzJGblpYTXZNVGN6T1RJd01EVTJNREV5TUE9PXw0ZmZjZDZjMmJiMjM0YTcyZDk4YzA4ZGQ0OWU1ZDQxNXwzMjg5NWIxNTY0MGQ0M2E3YjMxNTg5NTRlZGZmNjgyMA%3D%3D&sdata=Ny9RL2tPUmhBV3A5WkxLc0pySjA5SVIxZHFoeGRJcFRHR2dXZnZkQzNKND0%3D&ovuser=a102dde4-44a4-43dd-92cb-53c543fc0f0c%2Cmurat.sahin%40inevvo-solutions.com&OR=Teams-HL&CT=1739263428880&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yNTAxMDYyMDQxMiIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D"
    return (
        <div className='editor_footer_wrapper'>
            {type === ComponentType.GEMUE_INTERNAL ?
                <a className='dtu_logo_wrapper' href={dtuUrl} target='_blank' rel='noreferrer'>
                    <span className='pb_text'>powered by</span>
                    <span className='dtu'>DTU</span>
                </a>
                : <a href="https://inevvo-solutions.com/" target='_blank' rel='noreferrer'>
                    <img className='pb_logo' src={pbLogo} alt="" />
                </a>}
        </div>
    )
}

export default EditorFooter

import inevvoLogo from '../../../assets/inevvo_logo.png'
import { ComponentType } from '../../../models/componentType.enum'
import { Utils } from '../../../utils/Utils'
import LanguageSelector from '../../languageSelector/LanguageSelector'
import './ManufacturerIconComp.scss'

interface IconProps {
    manufacturerIcon?: { iconFile: File, iconUrl: string },
    manufacturerUrl?: string,
    type: ComponentType,
    isEditorView?: boolean,
    selectedLanguage?: string,
    setSelectedLanguage?: (language: string) => void
}

const ManufacturerIconComp: React.FC<IconProps> = ({
    manufacturerIcon,
    manufacturerUrl,
    type,
    isEditorView,
    selectedLanguage,
    setSelectedLanguage
}) => {
    const renderManufacturerLogo = () => {
        if (type === ComponentType.GEMUE_INTERNAL && !manufacturerIcon?.iconUrl) return null;

        const url = manufacturerIcon?.iconUrl
            ? decodeURIComponent(manufacturerIcon.iconUrl)
            : inevvoLogo;

        const href = manufacturerIcon?.iconUrl
            ? manufacturerUrl
            : Utils.arrangeUrl(manufacturerUrl!);

        return (
            <a href={href} target='_blank' rel='noreferrer'>
                <img className='manufacturer_logo' src={url} alt='manufacturer_logo' />
            </a>
        );
    };

    const renderLanguageSelector = () => {
        if (isEditorView) return null;

        return (
            <div className="language_selector webview_language_selector">
                <LanguageSelector
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                />
            </div>
        );
    };

    return (
        <div className='manufacturer_logo_wrapper'>
            {renderManufacturerLogo()}
            {renderLanguageSelector()}
        </div>
    );
};

export default ManufacturerIconComp;
import { FormTexts } from '../../../language/Texts'
import { ComponentType } from '../../../models/componentType.enum'
import InputComponent from '../inputComponent/InputComponent'
import FooterButton from './FooterButton'
import FooterCheckBox from './FooterCheckBox'
import './FormFooter.scss'

interface FormFooterProps {
  ownerEmail: string,
  setOwnerEmail: (email: string) => void,
  isPrivacyPolicyAccepted: boolean,
  setIsPrivacyPolicyAccepted: (isAccepted: boolean) => void,
  isAdvertisingAgreed: boolean,
  setIsAdvertisingAgreed: (isAccepted: boolean) => void,
  alertOpt: { message: string, loader: boolean },
  type: ComponentType
}

function FormFooter({
  ownerEmail, setOwnerEmail,
  isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted,
  isAdvertisingAgreed, setIsAdvertisingAgreed,
  alertOpt, type }: FormFooterProps) {
  return (
    <div className="form_footer">
      <InputComponent
        label={FormTexts.email}
        value={ownerEmail}
        handleChange={setOwnerEmail}
        isRequired={true}
        type='email' />

      {type !== ComponentType.GEMUE_INTERNAL && <FooterCheckBox
        isPrivacyPolicyAccepted={isPrivacyPolicyAccepted}
        setIsPrivacyPolicyAccepted={setIsPrivacyPolicyAccepted}
        isAdvertisingAgreed={isAdvertisingAgreed}
        setIsAdvertisingAgreed={setIsAdvertisingAgreed}
      />}
      <FooterButton
        isPrivacyPolicyAccepted={isPrivacyPolicyAccepted}
        alertOpt={alertOpt}
        type={type}
      />
    </div>
  )
}

export default FormFooter

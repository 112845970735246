import conexoMan from '../../../assets/conexoMan.png'
import battery from '../../../assets/battery.png'
import productShadowAddition from '../../../assets/productShadowAddition.png'
import './InfoHeader.scss'
import { Utils } from '../../../utils/Utils'
import { ComponentType } from '../../../models/componentType.enum'

interface InfoHeaderProps {
    productName: string | undefined,
    productIcon: { iconFile: File, iconUrl: string } | undefined,
    isEditorView?: boolean,
    type: ComponentType
}

function InfoHeader({ productName, productIcon, isEditorView, type }: InfoHeaderProps) {

    const defaultImage = Utils.isTypeBatteryPass(type) ? battery : conexoMan
    return (
        <div className='info_header_wrapper'>
            <div className='product_title_wrapper'>
                <p className={isEditorView ? 'editor_product_title product_title' : 'product_title'}>
                    {productName}
                </p>
                <p className={isEditorView ? 'editor_product_title_shadow product_title_shadow' : 'product_title_shadow'}>
                    {productName}
                </p>
            </div>
            <div className="product_image_wrapper" >
                {
                    productIcon?.iconUrl ?
                        <img className='product_image' src={decodeURIComponent(productIcon.iconUrl)} alt='product_icon' /> :
                        <img className='product_image' src={defaultImage} alt='conexo_product_icon' />
                }
                <img className='product_image_shadow' src={productShadowAddition} alt="product_image" />
            </div>

        </div>
    )
}

export default InfoHeader

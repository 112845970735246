import 'moment/locale/de'
import SnowfallComponent from '../../components/snowfallComponent/SnowfallComponent'
import AttributeComponent from '../../components/webViewComponents/attributeComponent/AttributeComponent'
import DocumentComponent from '../../components/webViewComponents/documentComponent/DocumentComponent'
import Footer from '../../components/webViewComponents/footer/Footer'
import General from '../../components/webViewComponents/general/General'
import InfoHeader from '../../components/webViewComponents/infoHeader/InfoHeader'
import ManufacturerIconComp from '../../components/webViewComponents/manufacturerIconComp/ManufacturerIconComp'
import QrCodeComponent from '../../components/webViewComponents/qrCodeComponent/QrCodeComponent'
import { IComponent } from '../../models/IComponent'
import { Utils } from '../../utils/Utils'
import './WebView.scss'

interface WebViewProps {
    isSeasonEffect: boolean
    component: IComponent
    isEditorView?: boolean
    componentQrCode?: string
    selectedLanguage?: string
    setSelectedLanguage?: (language: string) => void
}

function WebView({
    isSeasonEffect,
    component,
    isEditorView = false,
    componentQrCode,
    selectedLanguage,
    setSelectedLanguage }: WebViewProps) {
    let backgroundColor = Utils.getBackgroundColor(component.type)
    if (component.manufacturerColor) {
        backgroundColor = Utils.convertBackgroundColorToRgba(component.manufacturerColor)
    }
    const manufacturerIcon = { iconFile: new File([], ''), iconUrl: decodeURIComponent(component?.manufacturerIcon) }
    const productIcon = { iconFile: new File([], ''), iconUrl: decodeURIComponent(component?.productIcon) }
    document.title = component.productName

    return (
        <div className='wv_wrapper_1' data-testid='web_view'>
            {isSeasonEffect && <SnowfallComponent />}
            <div className='wv_wrapper_2' style={{ backgroundColor }}>
                <div className='container_wrapper'>
                    <ManufacturerIconComp
                        manufacturerUrl={component.manufacturerUrl}
                        manufacturerIcon={manufacturerIcon}
                        type={component.type}
                        isEditorView={isEditorView}
                        selectedLanguage={selectedLanguage}
                        setSelectedLanguage={setSelectedLanguage} />
                    <InfoHeader
                        productName={component.productName}
                        productIcon={productIcon}
                        isEditorView={isEditorView}
                        type={component.type}
                    />
                    <div className='info_wrapper'>
                        <General
                            serialNumber={component.serialNumber}
                            materialNumber={component.materialNumber}
                            date={component.productionDate}
                            manufacturerUrl={component.manufacturerUrl}
                            manufacturerName={component.manufacturerName}
                            isEditorView={isEditorView}
                        />
                        <AttributeComponent
                            keyValues={component.keyValue}
                            isEditorView={isEditorView} />
                        <DocumentComponent
                            files={component.document}
                            isEditorView={isEditorView} />
                        {!isEditorView && <QrCodeComponent componentQrCode={componentQrCode} />}

                    </div>
                    <Footer
                        type={component.type}
                        isEditorView={isEditorView} />
                </div>
            </div>
        </div>
    )
}

export default WebView
import { Button } from 'react-bootstrap'
import { FormTexts } from '../../../language/Texts'
import Loader from '../../loader/Loader'
import { ComponentType } from '../../../models/componentType.enum'

interface ButtonProps {
    isPrivacyPolicyAccepted: boolean,
    alertOpt: { message: string, loader: boolean },
    type: ComponentType
}

function FooterButton({ isPrivacyPolicyAccepted, alertOpt, type }: ButtonProps) {
    let isButtonDisabled = !isPrivacyPolicyAccepted || alertOpt.loader
    if (type === ComponentType.GEMUE_INTERNAL) {
        isButtonDisabled = false
    }
    return (
        <div className="form_button_wrapper">
            <Button
                disabled={isButtonDisabled}
                className='form_save_button'
                type='submit'>
                {type === ComponentType.GEMUE_INTERNAL ? FormTexts.exampleQR : FormTexts.freeTest}
            </Button>
            <span
                style={{ display: alertOpt.message ? 'inline' : 'none' }}
                className='alert_text'>
                {alertOpt.message}
            </span>
            {alertOpt.loader && <Loader />}
        </div>
    )
}

export default FooterButton

import { Button } from 'react-bootstrap'
import conexoMen from '../../../assets/conexoMen.png'
import conexoMenGemue from '../../../assets/conexoMenGemue.png'
import footerBattery from '../../../assets/footerBattery.png'
import logo from '../../../assets/inevvo_logo.png'
import { WebViewTexts } from '../../../language/Texts'
import { ComponentType } from '../../../models/componentType.enum'
import { Utils } from '../../../utils/Utils'
import './Footer.scss'

interface FooterProps {
    isEditorView: boolean,
    type: ComponentType,
}

function Footer({ isEditorView, type }: FooterProps) {
    const getClassName = (className: string) => {
        if (!isEditorView) return className
        return `editor_${className} ${className}`
    }
    let linkUrl = 'https://inevvo-solutions.com/inevvo-solutions/digitales-typenschild-iec-61406/'
    if (type === ComponentType.GEMUE_INTERNAL) {
        linkUrl = 'https://gemugroup.sharepoint.com/sites/T_DTU/SitePages/DTU_Digitale_Produkte_DPP-Software.aspx?csf=1&web=1&e=Wt81nC'
    }
    const title = Utils.isTypeBatteryPass(type) ? WebViewTexts.footerTitleBatteryPass : WebViewTexts.footerTitle
    const info = Utils.isTypeBatteryPass(type) ? WebViewTexts.footerInfoTextBatteryPass : WebViewTexts.footerInfoText
    let footerImage = Utils.isTypeBatteryPass(type) ? footerBattery : conexoMen
    if (type === ComponentType.GEMUE_INTERNAL) {
        footerImage = conexoMenGemue
    }
    return (
        <div className='footer_wrapper'>
            {type !== ComponentType.GEMUE_INTERNAL && <img className='footer_logo' src={logo} alt="" />}
            <div className={getClassName("footer_main")}>
                <div className={getClassName("footer_info")}>
                    <div className={getClassName("footer_info_title")}>{title}</div>
                    <div className={getClassName("footer_info_text")}>{info}</div>
                    <Button className={getClassName('footer_button')}
                        href={linkUrl}
                        target="_blank" >
                        {WebViewTexts.footerButtonMoreInfoText}
                    </Button>
                </div>
                <div className={getClassName("footer_image_part")}>
                    <img className='footer_image' src={footerImage} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Footer
